<template>
  <div>
    <b-sidebar
      id="sidebar-backdrop"
      backdrop
      shadow
      bg-variant="white"
      sidebar-class="sidebar-lg"
      no-header
      right
      :visible="customerAddSidebarActive"
      @change="(val) => $emit('update:customer-add-sidebar-active', val)"
    >

      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Nuevo cliente
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

        <!-- Campos formulario -->
        <validation-observer ref="simpleRules">
          <b-form
            v-if="show"
            class="p-2"
            @submit="onSubmit"
            @reset="onReset"
          >
            <validation-provider
              v-slot="{ errors }"
              name="cliente"
              rules="required"
            >
              <b-form-group
                label="Cliente:"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="form.nombre"
                  placeholder="Ingresa el nombre del cliente"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Select Y/N -->
            <b-form-group
              label="NIT / CF"
              label-for="input-cf"
            >
              <b-form-select
                id="input-cf"
                v-model="form.cf"
                :options="cfOptions"
                required
                @change="setNITValue(form.cf)"
              />
            </b-form-group>

            <validation-provider
              v-slot="{ errors }"
              name="NIT"
              :rules="{ required: true, regex: /^[0-9]+(-?[0-9kK])?$/ }"
            >
              <b-form-group>
                <b-form-input
                  id="input-nit"
                  v-model="form.nit"
                  placeholder="Ingresa el NIT"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Lista de precios -->
            <validation-provider
              v-slot="{ errors }"
              name="lista de precios"
              rules="required"
            >
              <b-form-group
                label="Lista de precios:"
                label-for="select-category"
              >
                <b-form-select
                  v-if="priceLists"
                  v-model="form.listap"
                  :options="priceLists"
                  value-field="id"
                  text-field="label"
                  input-id="select-listap"
                  placeholder="Selecciona una lista de precios"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Categoría -->
            <validation-provider
              v-slot="{ errors }"
              name="categoría"
              rules="required"
            >
              <b-form-group
                label="Tipo de cliente:"
                label-for="select-category"
              >
                <b-form-select
                  v-if="customerCategories"
                  v-model="form.tipo"
                  :options="customerCategories"
                  value-field="id"
                  text-field="label"
                  input-id="select-category"
                  placeholder="Selecciona una categoría de cliente"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Vendedor -->
            <validation-provider
              v-if="userData().role === 'admin'"
              v-slot="{ errors }"
              name="vendedor"
              rules="required"
            >
              <b-form-group
                label="Vendedor:"
                label-for="select-category"
              >
                <b-form-select
                  v-if="customerVendors"
                  v-model="form.vendor"
                  :options="customerVendors"
                  value-field="id"
                  text-field="label"
                  input-id="select-category"
                  placeholder="Selecciona un vendedor"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Tipo de pago -->
            <validation-provider
              v-slot="{ errors }"
              name="tipo de pago"
              rules="required"
            >
              <b-form-group
                label="Tipo de pago:"
                label-for="select-payment-type"
              >
                <b-form-select
                  v-if="customerPaymentType"
                  v-model="form.forma_pago"
                  :options="customerPaymentType"
                  input-id="select-payment-type"
                  placeholder="Selecciona un tipo de pago"
                  @change="setCreditDays($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Días crédito -->
            <validation-provider
              v-if="form.forma_pago === 'CREDITO'"
              v-slot="{ errors }"
              name="días de crédito"
              rules="required"
            >
              <b-form-group
                label="Días de crédito:"
                label-for="select-credit-days"
              >
                <b-form-select
                  v-model="form.dias_credito"
                  :options="customerCreditDays"
                  input-id="select-credit-days"
                  placeholder="Selecciona los días de crédito"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Límite de crédito -->
            <validation-provider
              v-if="form.forma_pago === 'CREDITO'"
              v-slot="{ errors }"
              name="límite de crédito"
              rules="required|positive"
            >
              <b-form-group
                label="Límite de crédito: (en QTZ)"
                label-for="input-credit-limit"
              >
                <cleave
                  id="input-credit-limit"
                  v-model="form.limite_credito"
                  class="form-control"
                  :options="options.currency"
                  placeholder="Ingresa el límite de crédito"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Contacto -->
            <validation-provider
              v-slot="{ errors }"
              name="contacto"
              rules="required"
            >
              <b-form-group
                label="Contacto:"
                label-for="input-contact"
              >
                <b-form-input
                  id="input-contact"
                  v-model="form.contacto"
                  placeholder="Ingresa el nombre del contacto"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <b-form-group
              label="Número de celular:"
              label-for="input-cellphone"
            >
              <cleave
                id="input-cellphone"
                v-model="form.movil"
                class="form-control"
                :raw="false"
                :options="options.cellphoneDelimiter"
                placeholder="Número de celular"
              />
            </b-form-group>

            <b-form-group
              label="Número de teléfono para cobros:"
              label-for="input-phone"
            >
              <cleave
                id="input-phone"
                v-model="form.tel"
                class="form-control"
                :raw="false"
                :options="options.cellphoneDelimiter"
                placeholder="Número de teléfono para cobros"
              />
            </b-form-group>

            <!-- Días de cobro -->
            <validation-provider
              v-slot="{ errors }"
              name="días de cobro"
              rules="required"
            >
              <b-form-group
                label="Días de cobro:"
                label-for="select-payment-days"
              >
                <b-form-select
                  v-if="customerPaymentDays"
                  v-model="form.dias_cobro"
                  :options="customerPaymentDays"
                  input-id="select-payment-days"
                  placeholder="Selecciona el día de cobro"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              name="correo"
              rules="email"
            >
              <b-form-group
                label="Correo:"
                label-for="input-email"
              >
                <b-form-input
                  id="input-email"
                  v-model="form.email"
                  placeholder="Ingresa el correo"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              name="dirección de la factura"
              rules="max:200"
            >
              <b-form-group
                label="Dirección de la factura:"
                label-for="textarea-address-fact"
              >
                <b-form-textarea
                  id="textarea-address-fact"
                  v-model="form.direccion_factura"
                  placeholder="Dirección de la factura (200 caracteres máximo)"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              name="dirección de la entrega"
              rules="max:200"
            >
              <b-form-group
                label="Dirección de la entrega:"
                label-for="textarea-address-delivery"
              >
                <b-form-textarea
                  id="textarea-address-delivery"
                  v-model="form.direccion_entrega"
                  placeholder="Dirección de la entrega (200 caracteres máximo)"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              name="dirección de cobro"
              rules="max:200"
            >
              <b-form-group
                label="Dirección de cobro:"
                label-for="textarea-address"
              >
                <b-form-textarea
                  id="textarea-address"
                  v-model="form.direccion"
                  placeholder="Dirección de cobro del cliente (200 caracteres máximo)"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-if="departments.length > 0"
              v-slot="{ errors }"
              name="departamento"
              rules="required"
            >
              <b-form-group
                label="Departamento:"
                label-for="select-department"
              >
                <v-select
                  v-model="form.depto"
                  placeholder="Selecciona un departamento"
                  input-id="select-department"
                  :options="departments"
                  :clearable="true"
                  @input="val => setDepartment(val)"
                >
                  <template slot="no-options">
                    Lo siento, no se encontraron departamentos
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-if="municipalities.length > 0"
              v-slot="{ errors }"
              name="municipio"
              rules="required"
            >
              <b-form-group
                label="Municipio:"
                label-for="select-municipality"
              >
                <v-select
                  v-model="form.muni"
                  placeholder="Selecciona un departamento"
                  input-id="select-municipality"
                  :options="municipalities"
                  :clearable="true"
                >
                  <template slot="no-options">
                    Lo siento, no se encontraron municipios
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <b-form-group
              label="Observaciones:"
            >
              <b-form-textarea
                v-model="form.observaciones"
                placeholder="Comentarios y anotaciones relacionadas con el cliente"
              />
            </b-form-group>

            <b-form-group>
              <b-button
                type="submit"
                variant="primary"
              >
                Crear
              </b-button>
              <b-button
                style="margin-left: 5px;"
                type="reset"
                variant="danger"
              >
                Limpiar
              </b-button>
            </b-form-group>

            <div class="mt-1">
              &nbsp;
            </div>
            <div class="mt-1">
              &nbsp;
            </div>

          </b-form>
        </validation-observer>

      </template>

    </b-sidebar>
  </div>
</template>

<script>
import axios from '@axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import { required } from '@/utils/validations/validations'
import guatemala from '@/utils/data/guatemala'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    Cleave,
  },
  model: {
    prop: 'customerAddSidebarActive',
    event: 'update:customer-add-sidebar-active',
  },
  props: {
    customerAddSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,

      form: {
        cf: 'N',
        nit_cf: 'N',
        nit: '',
        tipo: '',
        listap: '',
        vendor: this.userData().role === 'vendor' ? this.userData().id : '',
        forma_pago: '',
        dias_cobro: '',
        dias_credito: 0,
        limite_credito: 0,
        nombre: '',
        razon: '',
        cliente: 1,
        direccion: '',
        direccion_factura: '',
        direccion_entrega: '',
        contacto: '',
        tel: '',
        movil: '',
        email: '',
        pais: 'GUATEMALA',
        depto: '',
        muni: '',
        activo: 0,
        observaciones: '',
      },

      show: true,

      options: {
        cellphoneDelimiter: {
          delimiters: ['-'],
          blocks: [4, 4],
          uppercase: true,
        },
        currency: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: '.',
          delimiter: ',',
          numeralDecimalScale: 0, // Número de decimales
        },
      },

      cfOptions: [
        { value: 'N', text: 'Requiere NIT' },
        { value: 'Y', text: 'Consumidor Final' },
      ],

      customerCategories: [],
      departments: [],
      municipalities: [],
      priceLists: [],
      customerVendors: [],
      customerPaymentType: [
        { value: 'CONTADO', text: 'CONTADO' },
        { value: 'CREDITO', text: 'CREDITO' },
      ],
      customerCreditDays: [
        { value: '8', text: '8' },
        { value: '15', text: '15' },
        { value: '30', text: '30' },
        { value: '45', text: '45' },
        { value: '60', text: '60' },
      ],
      customerPaymentDays: [
        { value: 'Lunes a Viernes', text: 'Lunes a Viernes' },
        { value: 'Lunes', text: 'Lunes' },
        { value: 'Martes', text: 'Martes' },
        { value: 'Miercoles', text: 'Miércoles' },
        { value: 'Jueves', text: 'Jueves' },
        { value: 'Viernes', text: 'Viernes' },
      ],
    }
  },

  created() {
    this.getCustomerCategories()
    this.getPriceLists()
    this.getDepartments()
    this.getCustomerVendors()
  },

  methods: {
    userData() {
      return JSON.parse(localStorage.getItem('KultiverAuthUser'))
    },

    onSubmit(event) {
      event.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          axios
            .post('accounting-system/entities', this.form)
            .then(response => {
              this.makeToast('success', 'Cliente agregado', `Se ha creado el cliente ${response.data.data.nombre}.`)
              this.clearForm()
              this.$emit('update:customer-add-sidebar-active', false)
              this.$emit('refreshData')
            })
            .catch(error => {
              this.showErrors(error)
            })
        } else {
          this.makeToast('danger', 'Error', 'No es posible crear el cliente.')
        }
      })
    },

    onReset(event) {
      event.preventDefault()
      this.clearForm()
    },

    clearForm() {
      // Reset our form values
      this.form.cf = 'N'
      this.form.nit_cf = 'N'
      this.form.nit = ''
      this.form.tipo = ''
      this.form.listap = ''
      this.form.vendor = ''
      this.form.forma_pago = ''
      this.form.dias_cobro = ''
      this.form.dias_credito = 0
      this.form.limite_credito = 0
      this.form.nombre = ''
      this.form.razon = ''
      this.form.cliente = 1
      this.form.direccion = ''
      this.form.direccion_factura = ''
      this.form.direccion_entrega = ''
      this.form.contacto = ''
      this.form.tel = ''
      this.form.movil = ''
      this.form.email = ''
      this.form.depto = ''
      this.form.muni = ''
      this.form.observaciones = ''

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },

    setNITValue(value) {
      if (value === 'Y') {
        axios
          .get('accounting-system/entities-nit')
          .then(response => {
            this.form.nit = response.data.data
            this.form.nit_cf = 'Y'
          })
          .catch(error => {
            this.showErrors(error)
          })
        document.getElementById('input-nit').readOnly = true
      } else {
        this.form.nit = ''
        this.form.nit_cf = 'N'
        document.getElementById('input-nit').readOnly = false
      }
    },

    setCreditDays(value) {
      if (value === 'CONTADO') {
        this.form.dias_credito = 0
        this.form.limite_credito = 0
      }
    },

    getCustomerCategories() {
      axios
        .get('accounting-system/entities-categories')
        .then(response => {
          response.data.data.forEach(element => {
            this.customerCategories.push({
              id: element.identidades_tipos,
              label: element.tipo,
            })
          })
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    getCustomerVendors() {
      const filterArray = [
        { field: 'active', value: 'Y' },
      ]

      axios
        .get(`system/users?filters=${JSON.stringify(filterArray)}`)
        .then(response => {
          response.data.data.forEach(element => {
            this.customerVendors.push({
              id: element.id,
              label: element.name,
            })
          })
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    getPriceLists() {
      const filterArray = [
        { field: 'activo', value: 1 },
      ]
      axios
        .get(`accounting-system/product-price-list?filters=${JSON.stringify(filterArray)}`)
        .then(response => {
          response.data.data.forEach(element => {
            this.priceLists.push({
              id: element.listap,
              label: element.descrip,
            })
          })
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    getDepartments() {
      this.departments = []
      Object.keys(guatemala).forEach(element => {
        this.departments.push({
          value: element,
          label: element,
        })
      })
    },

    setDepartment(dept) {
      this.municipalities = []
      if (dept) {
        this.form.depto = dept.value
        this.municipalities = guatemala[dept.value]
      }
    },
  },
}
</script>
