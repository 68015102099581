var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-backdrop","backdrop":"","shadow":"","bg-variant":"white","sidebar-class":"sidebar-lg","no-header":"","right":"","visible":_vm.customerAddSidebarActive},on:{"change":function (val) { return _vm.$emit('update:customer-add-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Nuevo cliente ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"simpleRules"},[(_vm.show)?_c('b-form',{staticClass:"p-2",on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('validation-provider',{attrs:{"name":"cliente","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Cliente:","label-for":"input-name"}},[_c('b-form-input',{attrs:{"id":"input-name","placeholder":"Ingresa el nombre del cliente","required":""},model:{value:(_vm.form.nombre),callback:function ($$v) {_vm.$set(_vm.form, "nombre", $$v)},expression:"form.nombre"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"NIT / CF","label-for":"input-cf"}},[_c('b-form-select',{attrs:{"id":"input-cf","options":_vm.cfOptions,"required":""},on:{"change":function($event){return _vm.setNITValue(_vm.form.cf)}},model:{value:(_vm.form.cf),callback:function ($$v) {_vm.$set(_vm.form, "cf", $$v)},expression:"form.cf"}})],1),_c('validation-provider',{attrs:{"name":"NIT","rules":{ required: true, regex: /^[0-9]+(-?[0-9kK])?$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"id":"input-nit","placeholder":"Ingresa el NIT","required":""},model:{value:(_vm.form.nit),callback:function ($$v) {_vm.$set(_vm.form, "nit", $$v)},expression:"form.nit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"lista de precios","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Lista de precios:","label-for":"select-category"}},[(_vm.priceLists)?_c('b-form-select',{attrs:{"options":_vm.priceLists,"value-field":"id","text-field":"label","input-id":"select-listap","placeholder":"Selecciona una lista de precios"},model:{value:(_vm.form.listap),callback:function ($$v) {_vm.$set(_vm.form, "listap", $$v)},expression:"form.listap"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"categoría","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo de cliente:","label-for":"select-category"}},[(_vm.customerCategories)?_c('b-form-select',{attrs:{"options":_vm.customerCategories,"value-field":"id","text-field":"label","input-id":"select-category","placeholder":"Selecciona una categoría de cliente"},model:{value:(_vm.form.tipo),callback:function ($$v) {_vm.$set(_vm.form, "tipo", $$v)},expression:"form.tipo"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),(_vm.userData().role === 'admin')?_c('validation-provider',{attrs:{"name":"vendedor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Vendedor:","label-for":"select-category"}},[(_vm.customerVendors)?_c('b-form-select',{attrs:{"options":_vm.customerVendors,"value-field":"id","text-field":"label","input-id":"select-category","placeholder":"Selecciona un vendedor"},model:{value:(_vm.form.vendor),callback:function ($$v) {_vm.$set(_vm.form, "vendor", $$v)},expression:"form.vendor"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),_c('validation-provider',{attrs:{"name":"tipo de pago","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tipo de pago:","label-for":"select-payment-type"}},[(_vm.customerPaymentType)?_c('b-form-select',{attrs:{"options":_vm.customerPaymentType,"input-id":"select-payment-type","placeholder":"Selecciona un tipo de pago"},on:{"change":function($event){return _vm.setCreditDays($event)}},model:{value:(_vm.form.forma_pago),callback:function ($$v) {_vm.$set(_vm.form, "forma_pago", $$v)},expression:"form.forma_pago"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),(_vm.form.forma_pago === 'CREDITO')?_c('validation-provider',{attrs:{"name":"días de crédito","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Días de crédito:","label-for":"select-credit-days"}},[_c('b-form-select',{attrs:{"options":_vm.customerCreditDays,"input-id":"select-credit-days","placeholder":"Selecciona los días de crédito"},model:{value:(_vm.form.dias_credito),callback:function ($$v) {_vm.$set(_vm.form, "dias_credito", $$v)},expression:"form.dias_credito"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),(_vm.form.forma_pago === 'CREDITO')?_c('validation-provider',{attrs:{"name":"límite de crédito","rules":"required|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Límite de crédito: (en QTZ)","label-for":"input-credit-limit"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"input-credit-limit","options":_vm.options.currency,"placeholder":"Ingresa el límite de crédito"},model:{value:(_vm.form.limite_credito),callback:function ($$v) {_vm.$set(_vm.form, "limite_credito", $$v)},expression:"form.limite_credito"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),_c('validation-provider',{attrs:{"name":"contacto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Contacto:","label-for":"input-contact"}},[_c('b-form-input',{attrs:{"id":"input-contact","placeholder":"Ingresa el nombre del contacto","required":""},model:{value:(_vm.form.contacto),callback:function ($$v) {_vm.$set(_vm.form, "contacto", $$v)},expression:"form.contacto"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"Número de celular:","label-for":"input-cellphone"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"input-cellphone","raw":false,"options":_vm.options.cellphoneDelimiter,"placeholder":"Número de celular"},model:{value:(_vm.form.movil),callback:function ($$v) {_vm.$set(_vm.form, "movil", $$v)},expression:"form.movil"}})],1),_c('b-form-group',{attrs:{"label":"Número de teléfono para cobros:","label-for":"input-phone"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"input-phone","raw":false,"options":_vm.options.cellphoneDelimiter,"placeholder":"Número de teléfono para cobros"},model:{value:(_vm.form.tel),callback:function ($$v) {_vm.$set(_vm.form, "tel", $$v)},expression:"form.tel"}})],1),_c('validation-provider',{attrs:{"name":"días de cobro","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Días de cobro:","label-for":"select-payment-days"}},[(_vm.customerPaymentDays)?_c('b-form-select',{attrs:{"options":_vm.customerPaymentDays,"input-id":"select-payment-days","placeholder":"Selecciona el día de cobro"},model:{value:(_vm.form.dias_cobro),callback:function ($$v) {_vm.$set(_vm.form, "dias_cobro", $$v)},expression:"form.dias_cobro"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"correo","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Correo:","label-for":"input-email"}},[_c('b-form-input',{attrs:{"id":"input-email","placeholder":"Ingresa el correo"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"dirección de la factura","rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Dirección de la factura:","label-for":"textarea-address-fact"}},[_c('b-form-textarea',{attrs:{"id":"textarea-address-fact","placeholder":"Dirección de la factura (200 caracteres máximo)","rows":"3"},model:{value:(_vm.form.direccion_factura),callback:function ($$v) {_vm.$set(_vm.form, "direccion_factura", $$v)},expression:"form.direccion_factura"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"dirección de la entrega","rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Dirección de la entrega:","label-for":"textarea-address-delivery"}},[_c('b-form-textarea',{attrs:{"id":"textarea-address-delivery","placeholder":"Dirección de la entrega (200 caracteres máximo)","rows":"3"},model:{value:(_vm.form.direccion_entrega),callback:function ($$v) {_vm.$set(_vm.form, "direccion_entrega", $$v)},expression:"form.direccion_entrega"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"dirección de cobro","rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Dirección de cobro:","label-for":"textarea-address"}},[_c('b-form-textarea',{attrs:{"id":"textarea-address","placeholder":"Dirección de cobro del cliente (200 caracteres máximo)","rows":"3"},model:{value:(_vm.form.direccion),callback:function ($$v) {_vm.$set(_vm.form, "direccion", $$v)},expression:"form.direccion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),(_vm.departments.length > 0)?_c('validation-provider',{attrs:{"name":"departamento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Departamento:","label-for":"select-department"}},[_c('v-select',{attrs:{"placeholder":"Selecciona un departamento","input-id":"select-department","options":_vm.departments,"clearable":true},on:{"input":function (val) { return _vm.setDepartment(val); }},model:{value:(_vm.form.depto),callback:function ($$v) {_vm.$set(_vm.form, "depto", $$v)},expression:"form.depto"}},[_c('template',{slot:"no-options"},[_vm._v(" Lo siento, no se encontraron departamentos ")])],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),(_vm.municipalities.length > 0)?_c('validation-provider',{attrs:{"name":"municipio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Municipio:","label-for":"select-municipality"}},[_c('v-select',{attrs:{"placeholder":"Selecciona un departamento","input-id":"select-municipality","options":_vm.municipalities,"clearable":true},model:{value:(_vm.form.muni),callback:function ($$v) {_vm.$set(_vm.form, "muni", $$v)},expression:"form.muni"}},[_c('template',{slot:"no-options"},[_vm._v(" Lo siento, no se encontraron municipios ")])],2),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),_c('b-form-group',{attrs:{"label":"Observaciones:"}},[_c('b-form-textarea',{attrs:{"placeholder":"Comentarios y anotaciones relacionadas con el cliente"},model:{value:(_vm.form.observaciones),callback:function ($$v) {_vm.$set(_vm.form, "observaciones", $$v)},expression:"form.observaciones"}})],1),_c('b-form-group',[_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Crear ")]),_c('b-button',{staticStyle:{"margin-left":"5px"},attrs:{"type":"reset","variant":"danger"}},[_vm._v(" Limpiar ")])],1),_c('div',{staticClass:"mt-1"},[_vm._v("   ")]),_c('div',{staticClass:"mt-1"},[_vm._v("   ")])],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }